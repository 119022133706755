<template>
  <div>
    <form class="row mt-2">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
        <b-form-group
          label="速度 (秒)"
        >
          <b-input v-model.number="speed" type="number" min="0.1" step="0.1"></b-input>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <b-form-group label="加 +">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">位數</span>
            </div>

            <select
              v-model.number="digitPlue"
              class="custom-select"
            >
              <option
                v-for="(item, index) in digitsPlue"
                :key="index" :value="item.value"
                :selected="item.value == 1"
              >{{ item.text }}</option>
            </select>

            <div class="input-group-prepend">
              <span class="input-group-text">幾目</span>
            </div>

            <input
              v-model.number="quantityPlue"
              type="number"
              min="0"
              step="1"
            >
          </div>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <b-form-group label="減 -">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">位數</span>
            </div>

            <select
              v-model.number="digitMinus"
              class="custom-select"
            >
              <option
                v-for="(item, index) in digitsMinus"
                :key="index" :value="item.value"
                :selected="item.value == 1"
              >{{ item.text }}</option>
            </select>

            <div class="input-group-prepend">
              <span class="input-group-text">幾目</span>
            </div>

            <input
              v-model.number="quantityMinus"
              type="number"
              min="0"
              step="1"
            >
          </div>
        </b-form-group>
      </div>
      <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <b-form-group label="乘 *">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">位數</span>
            </div>

            <select
              v-model.number="digitMultiply"
              class="custom-select"
            >
              <option
                v-for="(item, index) in digitsMultiply"
                :key="index" :value="item.value"
                :selected="item.value == 1"
              >{{ item.text }}</option>
            </select>

            <div class="input-group-prepend">
              <span class="input-group-text">幾目</span>
            </div>

            <input
              v-model.number="quantityMultiply"
              type="number"
              min="0"
              step="1"
            >
          </div>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <b-form-group label="除 ÷">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">位數</span>
            </div>

            <select
              v-model.number="digitObelus"
              class="custom-select"
            >
              <option
                v-for="(item, index) in digitsObelus"
                :key="index" :value="item.value"
                :selected="item.value == 1"
              >{{ item.text }}</option>
            </select>

            <div class="input-group-prepend">
              <span class="input-group-text">幾目</span>
            </div>

            <input
              v-model.number="quantityObelus"
              type="number"
              min="0"
              step="1"
            >
          </div>
        </b-form-group>
      </div> -->

      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3" style="margin-top: 25px;">
        <div class="form-check form-switch">
          <input v-model="sound" class="form-check-input" type="checkbox" id="fm-sound" disabled>
          <label class="form-check-label" for="fm-sound">聲音 <b>({{ sound ? '開啟' : '關閉' }})</b> <span class="text-danger">未完成</span></label>
        </div>      
      </div>
    </form>

    <hr>

    <div class="row">
      <main class="col-12 text-center">
        <b-button
          v-if="topics.length === 0 || end"
          pill
          variant="primary"
          size="lg"
          @click="setTopics"
        >開始</b-button>

        <!-- + 符號不顯示 -->
        <transition v-else-if="topic" name="zoom">
          <h1 v-if="topicShow" :style="`--animation-duration: ${speed}s`">{{ topic.replace(/\+/, '') }}</h1>
        </transition>
        <h1 v-else class="text-success">{{ start }}</h1>
      </main>
    </div>

    <div class="row">
      <div v-if="end === true" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
        <b-form-group
          label="請做答"
        >
          <b-input
            v-model.number="answer"
            type="number"
            min="1"
            autocomplete="off"
          ></b-input>
        </b-form-group>
      </div>
      <div v-if="end" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
        <label for="ft-show-topics" class="form-label">題目</label>
        <p>
          <span v-if="showTopic">{{ `${topics.join('')}=` }}</span>
          <span v-if="showTopic && showAnswer">{{ sum }}</span>
        </p>

        <div v-if="end" class="form-check form-switch">
          <input v-model="showTopic" class="form-check-input" type="checkbox" id="fm-show-topic" @change="showTopic == false ? showAnswer = false : null">
          <label class="form-check-label" for="fm-show-topic">題目{{ showTopic ? '顯示' : '隱藏' }}</label>
        </div>
        <div v-if="end" class="form-check form-switch">
          <input v-model="showAnswer" class="form-check-input" type="checkbox" id="fm-show-answer" :disabled="!showTopic">
          <label class="form-check-label" for="fm-show-answer">答案{{ showAnswer ? '顯示' : '隱藏' }}</label>
        </div>
      </div>
    </div>

    <div v-if="(answer && sum) && answer.toString().length >= sum.toString().length" class="row mt-4">
      <div class="col-12">
        <div v-if="answer == sum" class="alert alert-success" role="alert">正確</div>
        <div v-else class="alert alert-danger" role="alert">錯誤</div>
      </div>
    </div>
  </div>
</template>

<script>
import '@asika32764/vue-animate/dist/vue-animate.css';

export default {
  data () {
    return {
      quantity: 5,        // 幾目
      digit: 99,          // 位數

      quantityPlue: 1,      // + 幾目
      quantityMinus: 0,     // - 幾目
      // quantityMultiply: 0,  // * 幾目
      // quantityObelus: 0,    // ÷ 幾目

      digitPlue: 99,      // + 位數
      digitMinus: 99,     // - 位數
      // digitMultiply: 99,  // * 位數
      // digitObelus: 99,    // ÷ 位數

      digitsPlue: [     // + 位數
        { value: 9, text: '1 (個)'},
        { value: 99, text: '2 (十)'},
        { value: 999, text: '3 (百)'},
        { value: 9999, text: '4 (千)'},
        { value: 99999, text: '5 (萬)'},
        { value: 999999, text: '6 (十萬)'},
        { value: 9999999, text: '7 (百萬)'},
        { value: 99999999, text: '8 (千萬)'}
      ],
      digitsMinus: [    // - 位數
        { value: 9, text: '1 (個)'},
        { value: 99, text: '2 (十)'},
        { value: 999, text: '3 (百)'},
        { value: 9999, text: '4 (千)'},
        { value: 99999, text: '5 (萬)'},
        { value: 999999, text: '6 (十萬)'},
        { value: 9999999, text: '7 (百萬)'},
        { value: 99999999, text: '8 (千萬)'}
      ],
      // digitsMultiply: [ // * 位數
      //   { value: 9, text: '1 (個)'},
      //   { value: 99, text: '2 (十)'},
      //   { value: 999, text: '3 (百)'},
      //   { value: 9999, text: '4 (千)'},
      //   { value: 99999, text: '5 (萬)'},
      //   { value: 999999, text: '6 (十萬)'},
      //   { value: 9999999, text: '7 (百萬)'},
      //   { value: 99999999, text: '8 (千萬)'}
      // ],
      // digitsObelus: [   // ÷ 位數
      //   { value: 9, text: '1 (個)'},
      //   { value: 99, text: '2 (十)'},
      //   { value: 999, text: '3 (百)'},
      //   { value: 9999, text: '4 (千)'},
      //   { value: 99999, text: '5 (萬)'},
      //   { value: 999999, text: '6 (十萬)'},
      //   { value: 9999999, text: '7 (百萬)'},
      //   { value: 99999999, text: '8 (千萬)'}
      // ],

      speed: 0.5,         // 速度
      arithmetic: [],  // 四則運算
      setArithmetic: [],
      sound: false,       // 聲音
      topic: null,
      topicShow: false,
      topics: [],         // 題目
      answer: null,
      intervalID: null,
      start: 0,
      end: false,
      sum: 0,
      showTopic: false,
      showAnswer: false
    }
  },
  methods: {
    setTopics () {
      let tmp = 0;
      let time = 0;
      // let quantity = this.quantityPlue + this.quantityMinus + this.quantityMultiply + this.quantityObelus;
      let quantity = this.quantityPlue + this.quantityMinus;
      let quantityPlueTmp = this.quantityPlue;
      let quantityMinusTmp = this.quantityMinus;
      // let quantityMultiplyTmp = this.quantityMultiply;
      // let quantityObelusTmp = this.quantityObelus;
      
      this.arithmetic = [];
      this.topic = null;
      this.topics = [];
      this.start = 0;
      this.end = false;
      this.answer = null;
      this.sum = 0;
      this.showTopic = false;
      this.showAnswer = false;

      if (this.quantityPlue !== 0 && this.quantityPlue !== 1) {
        this.arithmetic.push('+')
      }
      if (this.quantityMinus !== 0) {
        this.arithmetic.push('-')
      }
      // if (this.quantityMultiply !== 0) {
      //   this.arithmetic.push('*')
      // }
      // if (this.quantityObelus !== 0) {
      //   this.arithmetic.push('/')
      // }

      for (let i = 0; i < quantity; i++) {
        tmp = this.getRangeRandom(1, this.digitPlue);

        if (i === 0) {
          this.topics.push(`${tmp}`);
        } else {
          let arithmetic = this.arithmetic[this.getRangeRandom(1, this.arithmetic.length) - 1]
          let index = -1;

          switch (arithmetic) {
            case '+':
              tmp = this.getRangeRandom(1, this.digitPlue);
              quantityPlueTmp--;

              if (quantityPlueTmp === 0) {
                index = this.arithmetic.findIndex(element => element === arithmetic);

                this.arithmetic.splice(index, 1);
              }
              break;
            case '-':
              tmp = this.getRangeRandom(1, this.digitMinus);
              quantityMinusTmp--;

              if (quantityMinusTmp === 0) {
                index = this.arithmetic.findIndex(element => element === arithmetic);

                this.arithmetic.splice(index, 1);
              }
              break;
            // case '*':
            //   tmp = this.getRangeRandom(1, this.digitMultiply);
            //   quantityMultiplyTmp--;

            //   if (quantityMultiplyTmp === 0) {
            //     index = this.arithmetic.findIndex(element => element === arithmetic);

            //     this.arithmetic.splice(index, 1);
            //   }
            //   break;
            // case '/':
            //   tmp = this.getRangeRandom(1, this.digitObelus);
            //   quantityObelusTmp--;

            //   if (quantityObelusTmp === 0) {
            //     index = this.arithmetic.findIndex(element => element === arithmetic);

            //     this.arithmetic.splice(index, 1);
            //   }
            //   break;
          }

          tmp = `${arithmetic}${tmp}`;

          this.topics.push(tmp);
        }
      }

      for (let i = 0; i < this.topics.length; i++) {
        if (i === 0) {
          this.sum = this.topics[i];
        } else {
          this.sum = eval(`${this.sum}${this.topics[i]}`);
        }
      }

      tmp = [3, 2, 1, '開始'];
      for (let i = 0, len = tmp.length; i < len; i++) {
        setTimeout(() => {
          this.start = tmp[i];
        }, time = 1000 * i + 1);
      }

      for (let i = 0, len = this.topics.length; i < len; i++) {
        setTimeout(() => {
          this.topic = this.topics[i];
          this.topicShow = false;

          if (i === len - 1) {
            setTimeout(() => {

              this.end = true;
            }, this.speed * 1000);
          }
        }, time + (this.speed * 1000 * (i + 2)));

        setTimeout(() => {
          this.topicShow = true;     
        }, time + (this.speed * 1000 * (i + 2)));
      }
    },
    /**
     * 取得區間內的亂數
     * 
     * @param {number} min
     * @param {number} max
     * @return number
     */
    getRangeRandom(min, max){
      return Math.floor(Math.random() * max) + min;
    }
  }
}
</script>

<style scoped>
main h1 {
  font-size: 8rem;
}
</style>